import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import slugify from 'slugify'
import Select from 'react-select'
import { navigate } from '@reach/router'
import Hero from '../components/hero'
import { ANIMATION, BREAKPOINTS } from '../styles/cssVariables'
import { capitalise, calculateDaysBetween } from '../utils/utils'
import Layout from '../components/layout/layout'
import SEO from '../components/layout/seo'
import Container from '../components/layout/container'
import HubCard from '../components/ui-kit/hubCard'

import SvgBlogPostIcon from '../images/icons/blog-post-icon.svg'
import SvgEbookIcon from '../images/icons/ebook-icon.svg'
import SvgPodcastIcon from '../images/icons/podcast-icon.svg'


import { customSelectStyle } from '../styles/selectStyle'
import { useEducation } from '../hooks/filters'
import { EmptyState, FilterWrapper, FeaturedContainer, FeaturedTitle, FilterContainer, FilterNav, GridContainer, HeroContainer, HeroImage, HeroPanel, HeroTitle, HeroWrapper, HighlightsContainer, MainContainer, PickedContainer, PickedItemContainer, PickedMetaContainer, PickedTitle } from '../components/education-hub/styled'

const EducationHubPage = ({
  data: {
    contentfulPageEducationHub: { seoTitle, seoDescription, hero, openGraphImage },
    featuredContent,
    pickedContent,
    educationHubMeta,
    allContentfulHubContent,
    directoryMeta
  },
  location
}) => {

  const { hubs, filters } = useEducation(
    allContentfulHubContent,
    educationHubMeta
  )
  const [featured] = featuredContent.edges;
  const heroHeading = hero.heading;
  const heroSubheading = hero.subheading; 
  const heroCtaText = hero.ctaText;
  const heroCtaDestination = hero.ctaDestination; 

  return (
    <Layout location={location}>
      <SEO
        pageTitle={seoTitle}
        seoDescription={seoDescription}
        // ogImage={openGraphImage.gatsbyImageData.images.fallback.src}
      />

      <Hero
        id="edhub"
        heading={heroHeading}
        subheading={heroSubheading}
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />


      <HighlightsContainer>
        <FeaturedContainer>
          <FeaturedTitle>Featured</FeaturedTitle>
          <HubCard content={featured.node} />
        </FeaturedContainer>

        <PickedContainer>
          <PickedTitle>More picks</PickedTitle>
          {pickedContent?.edges?.slice(0,3).map((item) => {
            const daysBetween = calculateDaysBetween(
              new Date(),
              new Date(item.node.publishDate)
            )

            return (
              <PickedItemContainer key={item.node.title}>
                <div>
                  <Link to={item.node.slug}>
                    <h3>{item.node.title}</h3>
                  </Link>
                  <PickedMetaContainer>
                    <p>By {item.node.author}</p>
                    &nbsp;•&nbsp;
                    <p>
                      {daysBetween === 0 && 'Today'}
                      {daysBetween === 1 && `${daysBetween} day ago`}
                      {1 < daysBetween &&
                        daysBetween <= 28 &&
                        `${daysBetween} days ago`}
                      {daysBetween > 28 &&
                        new Date(item.node.publishDate).toLocaleDateString(
                          'en-GB',
                          {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          }
                        )}
                    </p>
                  </PickedMetaContainer>
                </div>

                <div>
                  {item.node.type === 'Blog Post' && <SvgBlogPostIcon />}
                  {item.node.type === 'Ebook' && <SvgEbookIcon />}
                  {item.node.type === 'Podcast' && <SvgPodcastIcon />}
                </div>
              </PickedItemContainer>
            )
          })}
        </PickedContainer>
      </HighlightsContainer>


      <MainContainer>
        <FilterWrapper>
          <FilterContainer>
              <FilterNav>
                <Select
                  onChange={filters.typeChangeCb}
                  options={filters.types}
                  styles={customSelectStyle}
                  value={filters.selectedType}
                />
                <Select
                  onChange={filters.topicChangeCb}
                  options={filters.topics}
                  styles={customSelectStyle}
                  value={filters.selectedTopic}
                />
              </FilterNav>
            </FilterContainer>
          </FilterWrapper>

          {hubs.length > 0 ? (
            <GridContainer width={12}>
              {hubs?.map((hub) => {
                return (
                  <HubCard key={hub.node.slug} content={hub.node} />
                )
              })}
            </GridContainer>
          ) : (
            <EmptyState>
              <h3>No results</h3>
            </EmptyState>
          )}
      </MainContainer>
    </Layout>
  )
}

export default EducationHubPage;

export const educationHubQuery = graphql`
  query EducationHubQuery {
    contentfulPageEducationHub {
      seoTitle
      seoDescription
      openGraphImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 85)
      }
      hero {
        heading
        subheading
        ctaText
        ctaDestination
        
      }
    }

    featuredContent: allContentfulHubContent(
      sort: { fields: [publishDate], order: DESC }
      filter: { isFeatured: { eq: true } }
    ) {
      edges {
        node {
          title
          slug
          isPremium
          isFeatured
          type
          topic
          author
          publishDate
          excerpt
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77777777778)
          }
          attachedFile {
            file {
              url
            }
          }
          buttonDestination
        }
      }
    }

    pickedContent: allContentfulHubContent(
      sort: { fields: [publishDate], order: DESC }
      filter: { isPicked: { eq: true } }
    ) {
      edges {
        node {
          title
          slug
          isPremium
          isPicked
          type
          author
          publishDate
        }
      }
    }

    educationHubMeta: allContentfulHubContent {
      types: distinct(field: type)
      topics: distinct(field: topic)
    }

    allContentfulHubContent(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          isPremium
          isFeatured
          isPicked
          type
          topic
          author
          publishDate
          excerpt
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77777777778)
          }
          attachedFile {
            file {
              url
            }
          }
          buttonDestination
        }
      }
    }
  }
`
